<template>
  <div id="app">


    <!-- <div v-if="authState !== 'signedin'">You are signed out.</div>
    <amplify-authenticator>
      <div v-if="authState === 'signedin' && user">
        <div>Hello, {{user.username}}</div>
        <router-view />
      </div>
      <amplify-sign-out></amplify-sign-out>
    </amplify-authenticator> -->


      <!-- <div v-if="authState === 'foo'">
           <Home />
      </div> -->

      <div v-if="authState !== 'signedin'">You are signed out.

        <!-- <Home /> -->

        <amplify-authenticator username-alias="email">

                <amplify-sign-in
      slot="sign-in"
      username-alias="email"
      :form-fields.prop="formFields"
    ></amplify-sign-in>
        </amplify-authenticator> 

      </div>


      <div v-if="authState === 'signedin' && user">
        <div>Hello, {{ user.username }}</div>
            <Dashboard :user="user" :authState="authState" />
      </div>

  </div>
</template>


<style lang="scss">
#app {
  font-family: "Blinker", sans-serif;
  text-align: center;
}


amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  background: #9fbaff;

}



.mini_banner {
  //small bar behind text login
  position: absolute;
  width: 272px;
  height: 60px;
  left: 20px;
  background: #9fbaff;
  border-radius: 100px;
}
.forget-psw {
  text-decoration: underline;
}
.forget-psw:hover {
  cursor: pointer;
}
.headerDivider {
  // lign between section in large screen
  position: absolute;
  left: 50%;
  margin-top: 40px;
  border-right: 1px solid #3a50b3;
  height: 80%;
}
@media (max-width: 500px) {
  .card {
    border-radius: 0px;
  }
  .title-open-session {
    margin-top: 10px;
    font-size: 32px;
  }
}




</style>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import Dashboard from "./views/Dashboard";
//import Home from "./views/Home";


export default {
  name: 'AuthStateApp',

  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    })
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      formFields: [
        {
          type: 'email',
          label: 'Adresse courriel',
          placeholder: 'custom email placeholder',
          required: true,
        },
        {
          type: 'password',
          label: 'Custom Password Label',
          placeholder: 'custom password placeholder',
          required: true,
        }
      ]

    }
  },
  components: {
   Dashboard,
   //Home
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  }
}

</script>


