<template>
  <div class="summary">
    <content-loader
      v-if="skeletonVisible"
      :width="100"
      :height="10"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#3a50b3"
    >
      <rect x="10" y="5" rx="1" ry="1" width="40" height="5" />
      <rect x="56" y="5" rx="1" ry="1" width="38" height="5" />
    </content-loader>

    <div class="row">
      <div class="col m8" style="padding-top:15px">
        <loading
          :active.sync="isPosting"
          :can-cancel="false"
          :on-cancel="onCancel"
          :background-color="backColorPosting"
          :color="spinnerPostingColor"
          :transionFade="transionFadeName"
          :opacity="0.1"
        ></loading>
        <div v-if="!skeletonVisible">
          <div class="col m3 s12">
            <div class="label-small-text">Totals des ventes</div>
            <div class="label-number">{{ salesTotal() }} $</div>
          </div>
          <div class="col m9 s12">
            <div class="row secondRow">
              <div class="col m6 s6">
                <div class="label-small-text">Nombre de factures</div>
                <div class="label-number">{{ receipts.length }}</div>
              </div>
              <div class="col m6 s6">
                <div class="label-small-text">Totals des taxes</div>
                <div class="label-number">{{ taxesToPay().toFixed(2) }} $</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col m4 addButton">
        <div v-if="!skeletonVisible">
          <button
            class="button"
            @click="openAddReceipt()"
            v-if="!addReceiptPressed"
          >
            AJOUTER UNE FACTURE
          </button>
          <button
            class="button"
            @click="openAddReceipt()"
            v-if="addReceiptPressed"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>

    <form @submit.prevent="submit">
      <div class="row">
        <div class="col m1"></div>
        <div class="col m10">
          <div class="addReceipt" v-show="addReceiptPressed">
            <div class="row">
              <form class="col s12">
                <div class="row">
                  <div class="input-field col s5">
                    <p class="small-text bold-text animated fadeInDown">
                      Client / Entreprise
                    </p>
                    <input
                      id="autocomplete-input"
                      class="autocomplete animated fadeInDown slow"
                      autocomplete="off"
                      placeholder="Nom"
                    />
                  </div>
                  <div
                    class="input-field col s3 form-group"
                    :class="{ 'form-group--error': $v.receipt.sales.$error }"
                  >
                    <p class="small-text bold-text animated fadeInDown">
                      Ventes
                    </p>
                    <input
                      autocomplete="off"
                      id="sales"
                      v-model.trim="$v.receipt.sales.$model"
                      class="form__input animated fadeInDown slow"
                      @keyup="updateTaxes"
                      placeholder="Ventes Net"
                    />
                  </div>
                  <div class="input-field col s2">
                    <p class="small-text bold-text animated fadeInDown">
                      TPS: 5.00 %
                    </p>
                    <input
                      id="tps"
                      autocomplete="off"
                      v-model="receipt.tps"
                      @keyup="removeLetter"
                      class="animated fadeInDown slow"
                      placeholder="TPS"
                    />
                  </div>
                  <div class="input-field col s2">
                    <p class="small-text bold-text animated fadeInDown">
                      TVQ: 9.975 %
                    </p>
                    <input
                      id="tvq"
                      autocomplete="off"
                      v-model="receipt.tvq"
                      class="animated fadeInDown slow"
                      placeholder="TVQ"
                    />
                  </div>
                  <div class="input-field col s2">
                    <p class="small-text bold-text animated fadeInDown">
                      File
                    </p>
                    <input
                      type="file"
                      id="file_receipt"
                      class="animated fadeInDown slow"
                      accept=".pdf, .xls, .xlsx, .doc, .docx"
                    />
                    <!-- //v-on:model="receipt.file_receipt" -->
                  </div>
                </div>
              </form>
            </div>

            <div class="addReceipt" v-show="addMoreDetailPressed">
              <div class="col s12">
                <div class="row">
                  <div class="input-field col s4">
                    <p class="small-text bold-text animated fadeInDown">
                      Nom du contact
                    </p>
                    <input
                      class="animated fadeInDown slow"
                      autocomplete="off"
                      v-model="receipt.contact"
                      placeholder="Nom du contact"
                    />
                  </div>
                  <div class="input-field col s4">
                    <p class="small-text bold-text animated fadeInDown">
                      Date
                    </p>
                    <input
                      class="animated fadeInDown slow"
                      type="date"
                      @keyup="removeLetter"
                      v-model="receipt.date"
                      autocomplete="off"
                      value="this.date"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col s12">
                <button
                  class="button animated fadeInDown slow"
                  type="submit"
                  :disabled="loading"
                >
                  <div v-if="loading" class="lds-ring-container">
                    <div class="lds-dual-ring"></div>
                  </div>
                  AJOUTER
                </button>

                <button
                  class="button-mode-detail animated fadeInDown slow"
                  type="button"
                  @click="moreDetail"
                >
                  {{ button.text }}
                </button>
              </div>
              <div class="row">
                <div class="col s12">
                  <p
                    class="typo__p bold-text animated fadeInUp"
                    style="color:green"
                    v-if="submitStatus === 'OK'"
                  >
                    Reçu ajouté avec succès.
                  </p>
                  <p
                    class="typo__p bold-text err animated fadeInDown"
                    v-if="submitStatus === 'ERROR'"
                  >
                    Un montant de ventes est obligatoire.
                  </p>
                  <p
                    class="typo__p bold-text animated fadeInUp"
                    v-if="submitStatus === 'PENDING'"
                  >
                    En envoie...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import M from "materialize-css";
import { required } from "vuelidate/lib/validators";
var dateFormat = require("dateformat");

const TPS_RATIO = 0.05;
const TVQ_RATIO = 0.09975;

const d = new Date();
const currentDate = dateFormat(d, "yyyy-MM-dd");

var stripNonNumber = function(inStr) {
  const regex = /[^0-9.]/g;
  return inStr.replace(regex, "");
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "Summary",

  created() {
    setTimeout(() => (this.skeletonVisible = false), 2000);
    this.fetchClients();
  },
  mounted: function() {
    setTimeout(() => {
      var elems = document.querySelectorAll(".autocomplete");

      const myObj = JSON.parse(
        JSON.stringify(this.$store.state.clients.clients)
      ).reduce((a, key) => Object.assign(a, { [key]: null }), {});

      var options = {
        data: myObj,

        onAutocomplete: function(res) {
          var x = document.getElementById("autocomplete-input");
          x.setAttribute("text", res);
        },
      };

      M.Autocomplete.init(elems, options);
    }, 4000);
  },

  components: {
    ContentLoader,
    Loading,
  },
  props: {
    user: Object,
    authState: String,
    receipts: Array,
  },

  data() {
    return {
      clients: [],
      sales: undefined,
      taxes: undefined,
      isPosting: false,
      backColorPosting: "#3498DB",
      spinnerPostingColor: "#3498DB",
      transionFadeName: "fade",
      skeletonVisible: true,
      addReceiptPressed: false,
      submitStatus: null,
      loading: false,
      addMoreDetailPressed: false,
      // receipt form field
      receipt: {
        tvq: "",
        tps: "",
        sales: "",
        contact: "",
        date: currentDate,
      },
      button: {
        text: "Plus de détail",
      },
    };
  },

  validations: {
    receipt: {
      sales: {
        required,
      },
    },
  },

  methods: {
    //...mapActions([ "fetchClients"]),
    salesTotal() {
      const reducer = (sum, val) => sum + parseFloat(val.sales);
      return this.$parent.receipts.reduce(reducer, 0.0);
    },

    taxesToPay() {
      const reducer = (sum, val) => sum + parseFloat(val.tps + val.tvq);
      return this.$parent.receipts.reduce(reducer, 0.0);
    },

    fetchClients() {
      try {
        this.$Amplify.API.get("ConsoleTpsTvqRestApi-prod", "/client", {
          headers: {
            Authorization: this.$parent.idToken(),
          },
        }).then(
          (response) => {
            return response.map((item) => item.name);
          },
          (error) => {
            console.log(`An error has occurred: ${error}`);
          }
        );
      } catch (e) {
        console.log("Unable to refresh Token", e);
      }
    },

    clearFormAddReceipt() {
      for (let field in this.receipt) {
        this.receipt[field] = "";
      }
      // set the current date
      this.receipt.date = currentDate;
    },

    addReceipt(current_receipt) {
      var d = new Date();
      var n = d.getTime();

      var apiPost = (params) => {
        try {
          return this.$Amplify.API.post(
            "ConsoleTpsTvqRestApi-prod",
            "/receipt",
            {
              headers: {
                Authorization: this.$parent.idToken(),
              },
              body: params,
            }
          );
        } catch (e) {
          console.error(`Unable to addReceipt: ${e} `);
        }
      };

      var body = {
        tps: current_receipt.cTPS,
        tvq: current_receipt.cTVQ,
        sales: current_receipt.cSales,
        CUSTOMERNAME: current_receipt.cCustomerName,
        id: `${n}_${current_receipt.cSales}`,
        date: current_receipt.cDate,
        contact: current_receipt.cContact,
      };

      var file = current_receipt.cFiles[0];
      if (file) {
        toBase64(file).then(
          (base64) => {
            body["base64File"] = {
              base64: base64,
              filename: file.name,
            };
            console.log(body);
            return apiPost(body);
          },
          (error) => {
            console.error(`An error has occurred: ${error}`);
          }
        );
      } else {
        console.log(body);
        return apiPost(body);
      }
    },

    removeLetter: function(event) {
      this.receipt.tps = stripNonNumber(event.target.value);
      this.receipt.tvq = stripNonNumber(event.target.value);
    },

    updateTaxes: function(event) {
      let netSalesBeforeTaxes = isNaN(event.target.value)
        ? ""
        : parseFloat(event.target.value);

      this.receipt.sales = stripNonNumber(event.target.value);
      this.receipt.tps = (netSalesBeforeTaxes * TPS_RATIO).toFixed(2);
      this.receipt.tvq = (netSalesBeforeTaxes * TVQ_RATIO).toFixed(2);
    },

    moreDetail() {
      this.addMoreDetailPressed = !this.addMoreDetailPressed;
      if (this.addMoreDetailPressed) {
        this.button.text = "Cacher";
      } else {
        this.button.text = "Plus de détail";
      }
    },

    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.loading = true;
        this.isPosting = true;
        this.submitStatus = "PENDING";

        if (this.receipt.tps == "") {
          this.receipt.tps = 0;
        }

        if (this.receipt.tvq == "") {
          this.receipt.tvq = 0;
        }

        const cFiles = document.getElementById("file_receipt").files;
        const cTPS = this.receipt.tps;
        const cTVQ = this.receipt.tvq;
        const cSales = this.receipt.sales;
        const cCustomerName = document.getElementById("autocomplete-input")
          .value;
        const cDate = this.receipt.date;
        const cContact = this.receipt.contact;

        this.addReceipt({
          cTPS,
          cTVQ,
          cSales,
          cCustomerName,
          cDate,
          cContact,
          cFiles,
        }).then((response) => {
          console.log(`addReceipt response: ${response}`);

          // change the message for completed but with a delay to see the sending...
          this.submitStatus = "OK";
          // empty the form
          document.getElementById("autocomplete-input").value = "";
          this.clearFormAddReceipt();

          this.$parent.refreshReceipts();

          setTimeout(() => {
            // close the addReceipt box
            this.isPosting = false;
            this.loading = false;
            this.addMoreDetailPressed = false;
            this.addReceiptPressed = !this.addReceiptPressed;
          }, 2500);
        }),
          (error) => {
            this.loading = false;
            console.error("Fail to add item : ", error);
          };
      }
    },

    openAddReceipt() {
      this.addReceiptPressed = !this.addReceiptPressed;
      this.addMoreDetailPressed = false;
      this.submitStatus = null;
      this.clearFormAddReceipt();
    },

    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
};
</script>

<style scoped>
p {
  margin-left: 0px;
}
.err {
  color: red;
}
.summary {
  height: 0px;
}
.label-small-text {
  font-size: 10px;
}
.label-number {
  font-size: 20px;
  margin-bottom: -10px;
  color: #3a50b3;
}
.addButton {
  padding-top: 4px;
}
.button {
  height: 50px;
  border: transparent;
  border-radius: 50px;
  font-size: 14px;
  width: 215px;
  color: white;
  margin: 10px;
  background: rgb(58, 80, 179);
  background: linear-gradient(
    22deg,
    rgba(58, 80, 179, 1),
    rgba(30, 112, 255, 1) 100%
  );
}
.button:hover {
  background: white;
  color: #3a50b3;
  cursor: pointer;
  border: 1px solid #3a50b3;
}
.button-mode-detail {
  margin-left: 30px;
  background: transparent;
  border: 0px;
  color: #3a50b3;
}
.button-mode-detail:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #3a50b3;
}
</style>
