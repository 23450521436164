<template>
  <div class="receiptsTable">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :background-color="backColor"
      :color="spinnerColor"
      :transionFade="transionFadeName"
      :opacity="0.1"
    ></loading>
    <loading
      :active.sync="isUpdating"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :background-color="backColorUpdate"
      :color="spinnerUpdateColor"
      :transionFade="transionFadeName"
      :opacity="0.2"
    ></loading>
    <content-loader
      v-if="skeletonVisible"
      :width="340"
      :height="84"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#3a50b3"
    >
      <rect x="10" y="10" rx="3" ry="3" width="67" height="11" />
      <rect x="86" y="10" rx="3" ry="3" width="140" height="11" />
      <rect x="127" y="58" rx="3" ry="3" width="53" height="11" />
      <rect x="187" y="58" rx="3" ry="3" width="72" height="11" />
      <rect x="18" y="58" rx="3" ry="3" width="100" height="11" />
      <rect x="235" y="10" rx="3" ry="3" width="37" height="11" />
      <rect x="18" y="33" rx="3" ry="3" width="140" height="11" />
      <rect x="166" y="33" rx="3" ry="3" width="153" height="11" />
    </content-loader>

    <v-dialog />

    <transition name="slide-fade">
      <modal name="edit-receipt" :width="600" :height="400" :adaptive="true">
        <div class="container" style="margin-top: 30px">
          <h4 class="left-align animated zoomIn">Modifier</h4>
          <form
            @submit.prevent="submit"
            class="col s12 animated fadeInRight"
            style="margin-top: 20px"
          >
            <div class="row">
              <div class="input-field col s6">
                <i class="material-icons white-text">delete</i>
                <input
                  id="name"
                  type="text"
                  class="validate"
                  autocomplete="off"
                  v-model="customer"
                />
                <label class="active" for="name">Nom</label>
              </div>
              <div class="input-field col s6">
                <input
                  autocomplete="off"
                  id="contact_input"
                  type="text"
                  class="validate"
                  v-model="contact"
                />

                <label class="active" for="contact_input">Contact</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s3">
                <input
                  autocomplete="off"
                  id="sales_input"
                  type="text"
                  class="validate"
                  v-model="sales"
                  @keyup="updateTaxes"
                />
                <label class="active" for="sales_input">Ventes</label>
              </div>
              <div class="input-field col s3">
                <input
                  autocomplete="off"
                  @keyup="removeLetter"
                  id="tps_input"
                  type="text"
                  class="validate"
                  v-model="tps"
                />
                <label class="active" for="tps_input">TPS</label>
              </div>
              <div class="input-field col s3">
                <input
                  autocomplete="off"
                  @keyup="removeLetter"
                  id="tvq_input"
                  type="text"
                  class="validate"
                  v-model="tvq"
                />
                <label class="active" for="tvq_input">TVQ</label>
              </div>
              <div class="input-field col s3" style="padding-bottom: 15px">
                <input
                  autocomplete="off"
                  id="date_input"
                  type="date"
                  class="validate"
                  v-model="created_date"
                />
                <label class="active" for="date_input">Date</label>
              </div>
              <div class="container animated zoomIn">
                <div class="col s6">
                  <button
                    type="submit"
                    class="modal-button"
                    id="modal-button-edit"
                    @click="submit()"
                  >
                    MODIFIER
                  </button>
                </div>
                <div class="col s6">
                  <button
                    type="button"
                    class="modal-button"
                    id="modal-button-cancel"
                    @click="hide()"
                  >
                    ANNULER
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col s12">
                  <p
                    class="typo__p bold-text animated fadeInUp"
                    style="color: green"
                    v-if="submitStatus === 'OK'"
                  >
                    Reçu ajouté avec succès.
                  </p>
                  <p
                    class="typo__p bold-text err animated fadeInDown"
                    v-if="submitStatus === 'ERROR'"
                  >
                    Un montant de ventes est obligatoire.
                  </p>
                  <p
                    class="typo__p bold-text animated fadeInUp"
                    v-if="submitStatus === 'PENDING'"
                  >
                    En envoie...
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </modal>
    </transition>

    <div v-if="!skeletonVisible" class="highlight centered responsive-table">
      <datatable
        title="Tableau"
        :columns="tableColumns1"
        :rows="receiptsWithAttachement()"
        locale="fr"
      >
        <th slot="thead-tr">Actions</th>
        <template slot="tbody-tr" slot-scope="props">
          <td>
            <button
              @click="(e) => edit_receipt(props.row, e)"
              class="btn blue darken-2 waves-effect waves-light compact-btn"
            >
              <i class="material-icons white-text">edit</i>
            </button>
          </td>
          <td>
            <button
              class="btn red darken-2 waves-effect waves-light compact-btn"
              @click="(e) => show(props.row, e)"
            >
              <i class="material-icons white-text">delete</i>
            </button>
          </td>
        </template>
      </datatable>
    </div>
  </div>
</template>

<style scoped>
.err {
  color: red;
}
.receipts {
  font-size: 12px;
}
.contact_table {
  font-size: 10px;
}
.delete-icons {
  margin-left: 30px;
}
.v--modal-overlay {
  background-color: rgba(159, 186, 255, 0.1);
}
.icon-toggle:hover {
  cursor: pointer;
}
.modal-button {
  width: 150px;
  height: 56px;
  border-radius: 50px;
  font-size: 14px;
}
.modal-button:hover {
  cursor: pointer;
}
#modal-button-edit {
  color: white;
  border: 1px solid #3a50b3;
  background-color: #3a50b3;
}
#modal-button-cancel {
  border: 0px;
  color: #3a50b3;
  background-color: white;
}
#modal-button-edit:hover {
  color: #3a50b3;
  background-color: white;
}
#modal-button-cancel:hover {
  text-decoration: underline;
  text-decoration-color: #3a50b3;
}
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from "vue-content-loader";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required } from "vuelidate/lib/validators";
import dateFormat from "dateformat";

//import Amplify, { Auth } from "aws-amplify";
//import Amplify from 'aws-amplify';

//import { AmplifyEventBus } from "aws-amplify-vue";
import DataTable from "vue-materialize-datatable";

const TPS_RATIO = 0.05;
const TVQ_RATIO = 0.09975;

var stripNonNumber = function(inStr) {
  const regex = /[^0-9.]/g;
  return inStr.replace(regex, "");
};

export default {
  components: {
    ContentLoader,
    Loading,
    datatable: DataTable,
  },
  props: {
    user: Object,
    authState: String,
    receipts: Array,
  },

  async beforeCreate() {
    // try {
    //  // await this.$Amplify.Auth.currentAuthenticatedUser();
    //   this.$store.state.user = await this.$Amplify.Auth.currentAuthenticatedUser();
    //   this.signedIn = true;
    // } catch (err) {
    //   this.signedIn = false;
    // }
    // AmplifyEventBus.$on("authState", (info) => {
    //   this.signedIn = info === "signedIn";
    // });
  },

  data() {
    return {
      receiptsWithAttachement: () => {
        return this.receipts.map((item) => {
          var newITem = item;
          if (item.s3url !== undefined)
            newITem.customer_with_attachement = `${item.CUSTOMER.name} <button onclick="window.open('${item.s3url}')" class="material-icons black-text" >attach_file</button>`;
          else newITem.customer_with_attachement = item.CUSTOMER.name;

          return newITem;
        });
      },
      signedIn: () => {
        this.authState !== "signedin";
      },
      submitStatus: null,
      skeletonVisible: true,
      isLoading: false,
      isUpdating: false,
      fullPage: true,
      backColor: "#ffd8d8",
      backColorUpdate: "#AED6F1",
      spinnerUpdateColor: "#3a50b3",
      spinnerColor: "#ffc4c4",
      transionFadeName: "fade",
      receipt_ID: null,
      file_url: null,
      customer: "",
      tps: "",
      tvq: "",
      sales: "",
      created_date: "",
      contact: "",
      tableColumns1: [
        {
          label: "client",
          field: "customer_with_attachement",
          numeric: false,
          html: true,
        },

        // {
        //   label: "Nom du client",
        //   field: "CUSTOMER.name",
        //   numeric: false,
        //   html: false,
        // },
        // {
        //   label: "Contact",
        //   field: "CUSTOMER.contact",
        //   numeric: false,
        //   html: false,
        // },
        {
          label: "Ventes",
          field: "sales",
          numeric: true,
          html: false,
        },
        {
          label: "TPS",
          field: "tps",
          numeric: true,
          html: false,
        },
        {
          label: "TVQ",
          field: "tvq",
          numeric: true,
          html: false,
        },
        {
          label: "Date",
          field: "created_date",
          numeric: false,
          html: false,
        },
      ],
    };
  },

  validations: {
    sales: {
      required,
    },
  },

  name: "ReceiptsTable",

  computed: mapGetters(["allReceipts"]),

  async created() {
    this.refreshReceipts();
  },

  methods: {
    refreshReceipts() {
      this.$parent.fetchReceipts().then(() => {
        this.skeletonVisible = false;
      });
    },
    updateReceipt(current_receipt) {
      var ddd = new Date(current_receipt.updated_Date);
      //var ddd = new Date();
      const params = {
        id: current_receipt.updated_Id,
        CUSTOMER: current_receipt.updated_Customer,
        sales: current_receipt.updated_Sales,
        tps: current_receipt.updated_TPS,
        tvq: current_receipt.updated_TVQ,
        created_date: dateFormat(ddd, "yyyy-MM-dd"),
        contact: current_receipt.updated_contact,
      };

      return this.$Amplify.API.patch("ConsoleTpsTvqRestApi-prod", "/receipt", {
        headers: {
          Authorization: this.$parent.idToken(),
        },
        body: params,
      })
    },

    deleteReceipt(receiptNumber) {
      return this.$Amplify.API.del("ConsoleTpsTvqRestApi-prod", "/receipt", {
        headers: {
          Authorization: this.$parent.idToken(),
        },
        body: {
          receiptId: receiptNumber,
        },
      });
    },

    removeLetter: function(event) {
      this.tps = stripNonNumber(event.target.value);
      this.tvq = stripNonNumber(event.target.value);
    },

    updateTaxes: function(event) {
      let netSalesBeforeTaxes = isNaN(event.target.value)
        ? ""
        : parseFloat(event.target.value);

      this.sales = stripNonNumber(event.target.value);
      this.tps = (netSalesBeforeTaxes * TPS_RATIO).toFixed(2);
      this.tvq = (netSalesBeforeTaxes * TVQ_RATIO).toFixed(2);
    },
    edit_receipt(row) {
      this.submitStatus = null;
      var c_receipt = row;
      //var ddd = new Date(c_receipt.created_date);

      this.customer = c_receipt.CUSTOMER.name.trimStart().trimEnd();
      this.contact = c_receipt.CUSTOMER.contact.trimStart().trimEnd();
      this.sales = c_receipt.sales;
      this.tps = c_receipt.tps;
      this.tvq = c_receipt.tvq;
      this.created_date = new Date(c_receipt.created_date)
        .toISOString()
        .split("T")[0];
      this.receipt_ID = row.id;
      // This is not tested
      this.file_url = c_receipt.receipt_file;

      // show the modal if the receipt exist
      this.$modal.show("edit-receipt");
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        if (this.tps == "") {
          this.tps = 0;
        }

        if (this.tvq == "") {
          this.tvq = 0;
        }

        const updated_Customer = this.customer.trimStart().trimEnd();
        const updated_contact = this.contact.trimStart().trimEnd();
        const updated_Sales = this.sales;
        const updated_TPS = this.tps;
        const updated_TVQ = this.tvq;
        const updated_Date = this.created_date;
        const updated_Id = this.receipt_ID;
        const updated_file_url = this.file_url;

        this.isUpdating = true;

        this.updateReceipt({
          updated_TPS,
          updated_TVQ,
          updated_Date,
          updated_Sales,
          updated_Customer,
          updated_Id,
          updated_contact,
          updated_file_url, //TODO: Not tested yet!
        })
          .then((response) => {
            console.log(`updateReceipt result : ${response}`);
            this.submitStatus = "OK";

            this.$parent.refreshReceipts();

            setTimeout(() => {
              // give more time for the store blurry animation spinner
              this.isUpdating = false;
              this.$modal.hide("edit-receipt");
            }, 1500);
          })
          .catch((error) => {
            console.error(`error updateReceipt: ${error} `);
          });
      }

      this.refreshReceipts();
    },

    show(row) {
      this.$modal.show("dialog", {
        title: "Attention!",
        text: "Voulez-vous supprimer ce reçu?",
        buttons: [
          {
            title: "SUPPRIMER",
            handler: () => {
              this.isLoading = true;
              this.deleteReceipt(row.id)
                .then(() => {
                  setTimeout(() => {
                    // give more time for the store blurry animation spinner
                    this.isLoading = false;
                    this.$parent.fetchReceipts();
                    this.$modal.hide("dialog");
                  }, 1000);
                })
                .catch((error) => {
                  console.error(`API call for deleting a item failed : ${error} `);
                  this.isLoading = false;
                });
            },
          },
          {
            title: "ANNULER",
          },
        ],
      });
    },

    download(receipt_file, receipt_file_url) {
      var filename = receipt_file.replace(/^.*[\\/]/, "");
      this.$modal.show("dialog", {
        title: "Voulez-vous telecharger ce reçu?",
        text: `reçu: ${filename} `,
        buttons: [
          {
            title: "telecharger",
            handler: () => {
              window.location.href = receipt_file_url;
              this.$modal.hide("dialog");
            },
          },
          {
            title: "ANNULER",
          },
        ],
      });
    },

    hide() {
      this.$modal.hide("edit-receipt");
    },

    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
};
</script>
