import axios from 'axios'
import { Auth } from "aws-amplify"

var config = require("../../config.json")

const getDefaultState = () => {
    return {
        clients: []
    }
};

const state = getDefaultState()

const getters = {
    allClients: state => state.clients
};

const actions = {

    async fetchClients({ commit }) {

        const currentSession = await Auth.currentSession();

        try {
            axios.get(`${config.aws_api_gateway.UserActions}/client`,
                {
                    headers: {
                        Authorization: currentSession.getIdToken().getJwtToken()
                    }
                }
            )
                .then((response) => {
                    const clients = response.data
                    var result = []

                    clients.forEach(function(item){
                        result.push(item.name)
                    })

                    commit('setClients', result)

                }, (error) => {
                    console.log(`An error has occurred: ${error}`)
                })

        } catch (e) {
            console.log('Unable to refresh Token', e)
        }
    }
};

const mutations = {
    setClients: (state, clients) => (state.clients = clients),
};

export default {
    state,
    getters,
    actions,
    mutations,
}