/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_3NS4qNU4m",
    "aws_user_pools_web_client_id": "2n83k1bhsl63e5i2ecaigjjms3",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "ConsoleTpsTvqRestApi-prod",
            "endpoint": "https://z53fyamvih.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        }
    ]
};


export default awsmobile;
