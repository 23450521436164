import Vue from 'vue'
import App from './App.vue'
//import router from './router/router'
import store from './store/index'

import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'

import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports);


Vue.use(Vuelidate)
Vue.prototype.$Amplify = Amplify
Vue.use(VModal, { dialog: true })
Vue.config.productionTip = false

new Vue({
 // router,
  store,
  render: h => h(App)
}).$mount('#app')
