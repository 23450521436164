<template>
  <div class="dashboard">
    <Header :current_user="user" :authState="authState" />
    <div class="container" style="margin-top:50px;">
      <h4 class="left-align">
        Sommaires pour {{ user.attributes.given_name }}
      </h4>
      <div class="col m12 z-depth-3">
        <div class="row table">
          <div class="col m12">
            <Summary :receipts="receipts" />
          </div>
        </div>
      </div>
      <div class="col s12">
        <h5 class="left-align">Factures</h5>
        <div class="card">
          <ReceiptsTable
            :user="user"
            :authState="authState"
            :receipts="receipts"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import ReceiptsTable from "../components/dashboard/ReceiptsTable";
import Summary from "../components/dashboard/Summary";
//import * as queries from './graphql/queries';
//import Amplify, { API, graphqlOperation } from 'aws-amplify';


function compareCreatedDate(receipt_a, receipt_b) {
  if (receipt_a.created_date > receipt_b.created_date) {
    return -1;
  }
  if (receipt_a.created_date < receipt_b.created_date) {
    return 1;
  }
  return 0;
}

export default {
  name: "Dashboard",
  data() {
    return {
      info: "",
      receipts: [],
    };
  },
  methods: {
    idToken() {
      return this.user.signInUserSession.getIdToken().getJwtToken();
    },
    fetchReceipts() {
      return new Promise((resolve, reject) => {
        this.$Amplify.API.get("ConsoleTpsTvqRestApi-prod", "/receipt", {
          headers: {
            Authorization: this.idToken(),
          },
        }).then(
          (response) => {
            this.receipts = response
              .sort(compareCreatedDate);
            resolve();
          },
          (error) => {
            console.log(`An error has occurred: ${error}`);
            reject(error);
          }
        );
      });
    },
  },
  components: {
    Header,
    Footer,
    ReceiptsTable,
    Summary,
  },
  props: {
    user: Object,
    authState: String,
  },
};
</script>

<style scoped>
.table {
  padding-top: 10px;
}
</style>
