//import axios from 'axios'
//import { Auth } from "aws-amplify"

//var config = require("../../config.json")

// function to sort created_date with the backend response.data

// const getDefaultState = () => {
//     return {
//         receipts: [],
//         receiptsCount: 0,
//         user: "",
//         salesTotal: 0,
//         taxes: 0
//     }
// };

// const state = getDefaultState()

// const getters = {
//     allReceipts: state => state.receipts,
//     countReceipts: state => state.receiptsCount,
//     salesTotal: state => state.salesTotal,
//     taxesToPay: state => state.taxes
// };




//const actions = {

    // async fetchReceipts( { commit } ,  amplify, idToken) {        

    //     try {

    //         var i = 1 ;
    //         var j = 1 ;
 
    //         if (i < j)
    //             commit('setReceiptsCount', 2);

    //         // return amplify.API.get("ConsoleTpsTvqRestApi-prod", '/receipt',
    //         //     {
    //         //         headers: {
    //         //             Authorization: idToken
    //         //         }
    //         //     }
    //         // );
           



    //         // return axios.get(`${config.aws_api_gateway.UserActions}/receipt`,
    //         //     {
    //         //         headers: {
    //         //             Authorization: currentSession.getIdToken().getJwtToken()
    //         //         }
    //         //     }
    //         // )
    //             //var it = 10;
                
            


            
    //             // .then((response) => {

    //             //     const receipts = response.data

    //             //     var counter = 0
    //             //     var ammounts = 0
    //             //     var taxes = 0

    //             //     receipts.forEach(function (item) {
    //             //         counter++
    //             //         ammounts += parseFloat(item.sales)
    //             //         taxes += parseFloat(item.tps + item.tvq)
    //             //     });

    //             //     // sort by created_date
    //             //     receipts.sort(compareCreatedDate);

    //             //     commit('taxesToPay', taxes.toFixed(2))
    //             //     commit('setReceipts', receipts)
    //             //     commit('setReceiptsCount', counter)
    //             //     commit('setSalesTotal', ammounts)
    //             // }, (error) => {
    //             //     console.log(`An error has occurred: ${error}`)
    //             // });

    //     } catch (e) {
    //         console.log('Unable to refresh Token', e)
    //     }
    // },

    // async addReceipt({ commit }, current_receipt, amplify) {
    //     var d = new Date();
    //     var n = d.getTime();

    //     // const cognitoUser = await Auth.currentAuthenticatedUser()

    //     // console.log(cognitoUser.signInUserSession.accessToken.jwtToken)

    //     // const currentSession = await Auth.currentSession();

    //     const currentSession = await amplify.Auth.currentSession();


    //     var params = {
    //         "tps": current_receipt.cTPS,
    //         "tvq": current_receipt.cTVQ,
    //         "sales": current_receipt.cSales,
    //         "CUSTOMERNAME": current_receipt.cCustomerName,
    //         "id": `${n}_${current_receipt.cSales}`,
    //         "date": current_receipt.cDate,
    //         "contact": current_receipt.cContact
    //     }

    //     var file = current_receipt.cFiles[0];
    //     if (file) {
    //         const base64 = await toBase64(file) ;
    //         params["base64File"] = {   
    //             base64: base64,
    //             filename: file.name
    //         };
    //     }
            
    //     console.log(params)

    //     try {
    //         return axios.post(`${config.aws_api_gateway.UserActions}/receipt`,
    //             params
    //             , {
    //                 headers: {
    //                     Authorization: currentSession.getIdToken().getJwtToken()
    //                 }
    //             }
    //         )
    //             .then(() => {
    //                 try {
    //                     axios.get(`${config.aws_api_gateway.UserActions}/receipt`,
    //                         {
    //                             headers: {
    //                                 Authorization: currentSession.getIdToken().getJwtToken()
    //                             }
    //                         }
    //                     )
    //                         .then((response) => {
    //                             const receipts = response.data

    //                             var counter = 0
    //                             var ammounts = 0
    //                             var taxes = 0
            
    //                             receipts.forEach(function (item) {
    //                                 counter++
    //                                 ammounts += parseFloat(item.sales)
    //                                 taxes += parseFloat(item.tps + item.tvq)
    //                             });

    //                             console.log("in response")

    //                             // sort by created_date
    //                             receipts.sort(compareCreatedDate);
    //                             commit('setReceipts', receipts)
    //                             commit('setReceiptsCount', counter)
    //                             commit('taxesToPay', taxes.toFixed(2))
    //                             commit('setSalesTotal', ammounts)

    //                         }, (error) => {
    //                             console.log(`An error has occurred: ${error}`)
    //                         })

    //                 } catch (e) {
    //                     console.log('Unable to refresh Token', e)
    //                 }
    //                 console.log(`adding a item (receiptID : ${current_receipt.cUser}) was a success`)
    //             }, (error) => {
    //                 console.log(`An error has occurred: ${error}`)
    //             })

    //     } catch (e) {
    //         console.log('Unable to refresh Token', e)
    //     }
    // },

    // async deleteReceipt({ commit }, receiptNumber, amplify) {

    //     // const cognitoUser = await Auth.currentAuthenticatedUser()

    //     // console.log(cognitoUser.signInUserSession.accessToken.jwtToken)

    //     // const currentSession = await Auth.currentSession();

    //     const currentSession = await amplify.Auth.currentSession();


    //     try {

    //         axios.delete(`${config.aws_api_gateway.UserActions}/receipt`,
    //             {
    //                 headers: {
    //                     Authorization: currentSession.getIdToken().getJwtToken()
    //                 },
    //                 data: {
    //                     receiptId: receiptNumber
    //                 }
    //             }
    //         )
    //             .then(() => {
    //                 console.log(`deleting receiptID : ${receiptNumber}`)

    //                 try {
    //                     axios.get(`${config.aws_api_gateway.UserActions}/receipt`,
    //                         {
    //                             headers: {
    //                                 Authorization: currentSession.getIdToken().getJwtToken()
    //                             }
    //                         }
    //                     )
    //                         .then((response) => {
    //                             const receipts = response.data

    //                             var counter = 0
    //                             var ammounts = 0
    //                             var taxes = 0
            
    //                             receipts.forEach(function (item) {
    //                                 counter++
    //                                 ammounts += parseFloat(item.sales)
    //                                 taxes += parseFloat(item.tps + item.tvq)
    //                             });

    //                             commit('taxesToPay', taxes.toFixed(2))
    //                             commit('setReceipts', receipts)
    //                             commit('setReceiptsCount', counter)
    //                             commit('setSalesTotal', ammounts)
    //                         }, (error) => {
    //                             console.log(`An error has occurred: ${error}`)
    //                         })

    //                 } catch (e) {
    //                     console.log('Unable to refresh Token', e)
    //                 }
    //             }, (error) => {
    //                 console.log(`An error has occurred: ${error}`)
    //             })

    //     } catch (e) {
    //         console.log('Unable to delete', e)
    //     }
    // },

    // async updateReceipt(_, current_receipt, amplify) {

    //     // const currentSession = await Auth.currentSession();

    //     const currentSession = await amplify.Auth.currentSession();


    //     const params = {
    //         "id": current_receipt.updated_Id,
    //         "CUSTOMER": current_receipt.updated_Customer,
    //         "sales": current_receipt.updated_Sales,
    //         "tps": current_receipt.updated_TPS,
    //         "tvq": current_receipt.updated_TVQ,
    //         "created_date": current_receipt.updated_Date,
    //         "contact": current_receipt.updated_contact
    //     }
    //     try {

    //         axios.patch(`${config.aws_api_gateway.UserActions}/receipt`,
    //             params,
    //             {
    //                 headers: {
    //                     Authorization: currentSession.getIdToken().getJwtToken()
    //                 }
    //             }
    //         ).then(() => {
    //             console.log(`updating receiptID : ${current_receipt.updated_Id}`)

    //         }, (error) => {
    //             console.log(`An error has occurred: ${error}`)
    //         })

    //     } catch (e) {
    //         console.log('Unable to update', e)
    //     }
    // }
//};

// const mutations = {
//     setReceipts: (state, receipts) => (state.receipts = receipts),
//     setReceiptsCount: (state, receiptsCount) => (state.receiptsCount = receiptsCount),
//     newReceipt: (state, receipt) => state.receipts.unshift(receipt),
//     setSalesTotal: (state, salesTotal) => (state.salesTotal = salesTotal),
//     taxesToPay: (state, taxes) => (state.taxes = taxes)
// };

export default {
    // state,
    // getters,
    // actions,
    // mutations,
}