<template>
  <header>
    <nav class="top">
      <div class="nav-wrapper">
        <img
          class="brand-logo"
          alt="Muh.mobi"
          src="../assets/logo-muhaye-blue.png"
          @click="goHome"
        />

        <ul class="navText left hide-on-med-and-down">
          <li class="li-nav-left">
            Automatiseur de taxes pour les travailleurs autonomes
          </li>
        </ul>
        <ul class="navText right hide-on-med-and-down">
          <li class="li-nav-right">Aide</li>
          <li class="li-nav-right" v-if="!signedIn">Tarifs</li>
          <li class="li-nav-right" v-if="!signedIn">Fonctionnalités</li>
          <li class="li-nav-right" v-if="!signedIn" @click="goHome">
            Connexion
          </li>
          <li
            class="li-nav-right"
            v-if="signedIn"
            @click="myAccount('/Profil')"
          >
            <div v-if="current_user">
              {{ current_user.attributes.email }}
            </div>
          </li>
          <li
            class="li-nav-right"
            v-if="signedIn"
            @click="myDashboard('/dashboard')"
          >
            Tableau de bord
          </li>
          <li class="li-nav-right" v-if="signedIn" @click="signOut">
            Déconnexion
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
//import { AmplifyEventBus } from "aws-amplify-vue";
//import { Auth } from "aws-amplify";

export default {
  name: "Header",
  props: {
    current_user: Object,
    authState: String
  },
  data() {
    return {
      signedIn: ()=> {
        this.authState !== 'signedin'
      }
      //,
      //current_user: this.$store.state.user,
    };
  },
  async beforeCreate() {
    // try {
    //   //await this.$Amplify.Auth.currentAuthenticatedUser();
    //   //this.current_user = await Auth.currentAuthenticatedUser();
    //   this.signedIn = true;
    // } catch (err) {
    //   this.signedIn = false;
    // }
    // // AmplifyEventBus.$on("authState", (info) => {
    // //   this.signedIn = info === "signedIn";
    // });
  },
  methods: {
    signOut() {
      this.$Amplify.Auth.signOut()
        .then((data) => {
          this.$store.state.signedIn = !!data;
          //this.$router.push({ path: "/" });
        })
        .catch((err) => console.log(err));
    },
    myAccount(destination) {
      if (this.$route.path !== destination)
        this.$router.push({ path: destination });
    },
    myDashboard(destination) {
      if (this.$route.path !== destination)
        this.$router.push({ path: destination });
    },
    goHome() {
      this.$router.go();
    },
  },
};
</script>

<style scoped>
.brand-logo:hover {
  cursor: pointer;
}
.top {
  background: transparent;
  box-shadow: none !important;
}
.signout {
  margin-top: -10px;
}
.navText {
  color: #3a50b3;
  font-size: 12px;
}
.li-nav-left {
  padding-left: 100px;
}
.li-nav-right {
  padding-right: 25px;
  padding-left: 25px;
}
.li-nav-right:hover {
  cursor: pointer;
  background-color: #3a50b3;
  color: white;
}
</style>
